import React, {useEffect} from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Topper from '../components/landing/topper'
import Register from '../components/landing/register'
import Footer from '../components/landing/footer'
// import Credits from '../components/landing/credits'

// markup
const IndexPage = () => {

  return (
    <Layout>
      <main>
        <Topper />

        <div className="section">
          <div className="section-body">
            <h3 className="section-title">What is PaverOps?</h3>
            <h4 className="section-desc">Our platform uses GIS (Geographic Information Systems) to:</h4>

            <div className="services-wrapper">
              <div className="service-item">
                <img src="/icons/checkmark.svg" />
                <p>Reduce impact to the public through streamlined project coordination</p>
              </div>

              <div className="service-item">
                <img src="/icons/checkmark.svg" />
                <p>Increase return on investment through cross-agency knowledge sharing</p>
              </div>

              <div className="service-item">
                <img src="/icons/checkmark.svg" />
                <p>Save valuable staff time and increase opportunities of project cost-sharing</p>
              </div>

              <div className="service-item">
                <img src="/icons/checkmark.svg" />
                <p>Improve inter-agency relationships through better communication and cooperation</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section whitebg">
          <div className="section-body">
            <h3 className="section-title">Solutions</h3>
            <div className="solution-item">
              <div className="solution-icon">
                <img src="/icons/road.svg" />
              </div>
              <div className="solution-text">
                <h4 className="section-desc">Pavement Plans & Moratoriums</h4>
                <p>Seamlessly communicate when paving is scheduled and when pavement cuts are prohibited. Enhance utility project planning and coordination while reducing unnecessary pavement damage.</p>
              </div>
            </div>
            <div className="solution-item">
              <div className="solution-icon">
                <img src="/icons/layers.svg" />
              </div>
              <div className="solution-text">
                <h4 className="section-desc">Utility Data Sharing</h4>
                <p>Access authoritative utility infrastructure data and gain project insight to reduce dig-ins and cross-boring incidents. Save time, money, and headache.</p>
              </div>
            </div>
            <div className="solution-item">
              <div className="solution-icon">
                <img src="/icons/handshake.svg" />
              </div>
              <div className="solution-text">
                <h4 className="section-desc">Current & Future Project Coordination</h4>
                <p>Simplify difficult interagency project management while creating new opportunities for synchronous projects, cost sharing, and reduced community impact.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="topper bg">
            <StaticImage src="../images/platform.jpg" alt="Overhead view of a crosswalk" />
            <div className="deck-wrapper">
              <h3 className="section-title">The PaverOps Platform</h3>
              
              <h4 className="section-desc">Easily upload your existing GIS data into ready to use templates and instantly share it with other members.</h4>
              <p>Whether you’re a city with a paving plan or a utility with a large capital plan, organizations of all sizes benefit from expanded data sharing and project coordination in the shared right-of-way.</p>

              <h4 className="section-desc">Protecting data privacy and ensuring security</h4>
              <p>Your data is sensitive — that’s why PaverOps only allows membership to local governments and utilities. Your infrastructure data stays secure, actionable, and trusted.</p>
            </div>
          </div>
        </div>
        <div className="section whitebg">
          <div className="section-body">
            <h3 className="section-title">How It Works</h3>
            <div className="half-flex">
              <div className="flex-text">
                <h4 className="section-desc">A web-based platform leveraging ESRI technology</h4>
                <p>PaverOps is never static. As a cloud GIS mapping system, we are constantly improving our platform and regularly pushing updates, which allows the entire PaverOps community to benefit.</p>
              </div>
              <div className="flex-art">
                <video src="https://paverops.s3.amazonaws.com/upload-static-assets/rectselect.mp4" loop autoPlay muted playsInline />
              </div>
            </div>
            <div className="half-flex reverse">
              <div className="flex-text">
                <h4 className="section-desc">Powerful and intuitive design</h4>
                <p>Robust software does not need to be cumbersome or challenging. Our focus on usability removes the learning curve and allows you to access decision ready information immediately.</p>
              </div>
              <div className="flex-art">
                <video src="https://paverops.s3.amazonaws.com/upload-static-assets/query.mp4" loop autoPlay muted playsInline />
              </div>
            </div>
            <div className="half-flex">
              <div className="flex-text">
                <h4 className="section-desc">No GIS data? No problem</h4>
                <p>PaverOps’ painless interface makes it easy to create and edit data right in the platform.</p>
              </div>
              <div className="flex-art">
                <video src="https://paverops.s3.amazonaws.com/upload-static-assets/drawing.mp4" loop autoPlay muted playsInline />
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="topper bg">
            <StaticImage src="../images/whatdrivesus.jpg" alt="Heavy machinery working in the street" />
            <div className="deck-wrapper side">
              <h3 className="section-title">What Drives Us</h3>
              
              <div className="right-split">
                <p>Our roads and utility infrastructure are the backbone of our daily life and the global interconnection built upon our local and regional systems. The organizations that build and maintain these networks are more important than ever in addressing issues of equity, a changing climate, and a rapidly changing regulatory environment.</p>

                <p>As decades of underfunding, deferred improvements, and the normal ending-of-life cycle of assets catch up, every dollar and every hour counts in their tight budgets and restricted workforce. There are many ways to make our world a little better — providing a software tool that provides real value to the public and utility sector is ours.</p>
              </div>
            </div>
          </div>
        </div>

        <Register />
        {/*<Credits />*/}
        <Footer landing={true} />
      </main>
    </Layout>
  )
}

export default IndexPage
