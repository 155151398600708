import React, {Fragment} from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Nav from '../ui/nav'

// markup
const Topper = () => {
  return (
    <div className="landing">
      <Nav page={"landing"} items={[
        // {url: "#whatwedo", text: "What we do"},
        // {url: "#whoweare", text: "Who we are"},
        {url: "#register", text: "Request demo"},
        {url: "/faq/", text: "FAQs"},
        {url: "/contact/", text: "Contact Us"},
        {url: "/app/", text: "Login"}
      ]} />
      <div className="topper">
        <div className="img-wrapper">
          <img src="/images/topperbg.png" alt="An angular slice of a road beind repaired with heavy machinery" />
        </div>
        <div className="deck-wrapper">
          <div className="deck">
            <h1>
              <img src="/logos/white-logo.svg" alt="PaverOps" />
            </h1>
            <h2 className="callout">An ESRI partner cultivating infrastructure coordination</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topper
