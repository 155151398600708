import React, {useEffect, useState, Fragment} from 'react'
import ComboBox from 'react-responsive-combo-box'
import { useCookies } from 'react-cookie'
import { decodeCookie } from '../../utils/constants.js'

// Get the URLs depending on our environment
let lambdaUri = 'http://localhost:8888'

const currentEnv = process.env.GATSBY_ENV
if (typeof currentEnv === "prod"){
  lambdaUri = 'https://www.paverops.com' 
}
if (typeof currentEnv === "stage"){
  lambdaUri = 'https://stage--paverops.netlify.app' 
}

// markup
const Register = () => {
  let [submitProgress, setSubmitProgress] = useState(false)
  let [company, setCompany] = useState("County")
  let [supportName, setSupportName] = useState("")
  let [supportEmail, setSupportEmail] = useState("")
  let [supportOrg, setSupportOrg] = useState("")
  let [supportCity, setSupportCity] = useState("")
  let [supportDates, setSupportDates] = useState("")
  let [supportFocus, setSupportFocus] = useState("")
  let [supportOther, setSupportOther] = useState("")

  const [cookies, setCookie, removeCookie] = useCookies(['paverReg'])
  // Safety check so we don't crash the app
  if (!cookies.paverReg){
    cookies.paverReg = {}
  } else {
    // Decode the result
    cookies.paverReg = decodeCookie(cookies.paverReg)
  }

  // Check if submit should be disabled
  let submitClass = "primary"
  if (!supportName || !supportEmail || !supportOrg || !supportCity || !supportDates || !supportFocus){
    submitClass += " disabled"
  }

  return (
    <div className="section spacer" id="register">
      <div className="section-body">
        <h3 className="section-title">Request Demo</h3>
        <div className="support-container">
          <div className="support-form-wrapper">
            <label htmlFor="support-name">Full name:</label>
            <input type="text" name="name" id="support-name" value={supportName} placeholder="Full name" disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
              setSupportName(e.currentTarget.value)
            }} />
            <label htmlFor="support-email">Email address:</label>
            <input type="text" name="email" id="support-email" value={supportEmail} placeholder="Email address" disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
              setSupportEmail(e.currentTarget.value)
            }} />
            <label htmlFor="support-org">Which organization are you part of?</label>
            <input type="text" name="organization" id="support-org" value={supportOrg} placeholder="Name of organization" disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
              setSupportOrg(e.currentTarget.value)
            }} />
            <label htmlFor="support-company">Type of organization:</label>
            <div className="field-box">
              <ComboBox 
                id="support-company"
                options={[
                  "County",
                  "Municipality",
                  "Transportation",
                  "Utility",
                  "Oil, Gas, and/or Chemical",
                  "Other"
                ]}
                editable={false}
                onSelect={(option) => {
                  setCompany(option)
                }}
                defaultValue={company}
                className="combo"
              />
            </div>
            {company === "Other" &&
              <Fragment>
                <label htmlFor="support-other">If other, please specify</label>
                <input type="text" id="support-other" value={supportOther} disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
                  setSupportOther(e.currentTarget.value)
                }} />
              </Fragment>
            }
            <label htmlFor="support-city">Location of organization:</label>
            <input type="text" name="location" id="support-city" value={supportCity} placeholder="City, State" disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
              setSupportCity(e.currentTarget.value)
            }} />
            <label htmlFor="support-dates">Please provide a few dates and times your team is available for a PaverOps demo:</label>
            <textarea id="support-dates" value={supportDates} placeholder="Ex. January 31st, 2022 between 2-4pm" disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
              setSupportDates(e.currentTarget.value)
            }}>
            </textarea>
            <label htmlFor="support-focus">Is there anything specific you would like us to focus on?</label>
            <input type="text" id="support-focus" value={supportFocus} placeholder="Any specific requirements or inquiries" disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
              setSupportFocus(e.currentTarget.value)
            }} />
            <button className={submitClass} onClick={() => {
              // Exit early if we're already in progress or button is disabled
              if (!submitProgress && submitClass.indexOf("disabled") === -1){
                setSubmitProgress("progress")
                // Get form data ready to send
                let formData = new FormData()
                formData.append('report', supportDates)
                formData.append('company', company)
                formData.append('focus', supportFocus)
                formData.append('city', supportCity)
                formData.append('org', supportOrg)
                formData.append('other', supportOther)
                formData.append('email', supportEmail)
                formData.append('username', supportName)
                formData.append('reason', "demo request")
                if (typeof navigator !== "undefined"){
                  formData.append('useragent', navigator.userAgent)
                }
                
                // Send the support req over
                fetch(lambdaUri+'/.netlify/functions/send-email', {
                  method: 'post',
                  body: formData
                })
                .then(response => response.json())
                .then(data => {
                  // Just set to done so we can't be spammed with requests
                  setSubmitProgress("done")
                  // Prep next message
                  setTimeout(() => {
                    setSubmitProgress("next")
                  }, 2000)
                })
              } else if (submitProgress === "next") {
                // Handle clearing the content for a new message
                setSupportName("")
                setSupportEmail("")
                setSupportOrg("")
                setSupportCity("")
                setSupportDates("")
                setSupportFocus("")
                setSupportOther("")

                // Ready to write again
                setSubmitProgress(false)
              }
            }}>
              {submitProgress ? (
                <Fragment>
                  {submitProgress === "progress" &&
                    <span className="loading">
                      <img src="/loading.png" alt="Loading icon" />
                    </span>
                  }
                  {submitProgress === "done" &&
                    <span>Submitted!</span>
                  }
                  {submitProgress === "next" &&
                    <span>New request</span>
                  }
                </Fragment>
              ) : (
                <span>Submit Request</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
